import {RouterDirection} from '@ionic/core';
import {AuthService} from '../services/auth.service';
import {FixedEncodeURIComponent} from './string-utils';
import {AccountService} from "../services/account.service";
import {StorageService} from "../services/storage.service";
import {i18n} from "../services/i18n.service";
import {ErrorToast} from "./default-toast";
import {ShoppingCartService} from "../services/shopping-cart.service";
import {EnvironmentService} from "../services/environment.service";
import {IError} from "../interfaces/error";
import {CouponService} from "../services/coupon.service";




export const GetDomain = () => {
  return location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
}


export const RouterNavigate = async (path:string, direction: RouterDirection = 'forward') => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.push(path, direction)
  }else{
    location.href=path;
  }
};


export const IsLoggedInGuardWithUserCheck = () => {
  const isLoggedIn = AccountService.isLoggedIn(AccountService.getAccount()?._id)
  if (isLoggedIn ) {
    return true;
  } else {
    StorageService.set('budget', true);
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const IsLoggedInGuardAdmin = () => {
  const isLoggedIn = AccountService.getAccount()?._id
  const isAdmin = AccountService.hasRole("admin")
  if (isLoggedIn && isAdmin) {
    return true;
  } else {
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const IsLoggedInGuard = () => {
  const isLoggedIn = AccountService.getAccount()?._id
  if (isLoggedIn) {
    return true;
  } else {
    return {redirect: '/not-logged-in'}; // If a user is not logged in, they will be redirected to the /login pages
  }
}

export const CanSeeArticles = () => {
  const isLoggedIn = AccountService.getAccount()?._id  || CouponService.getCoupon()?._id

  const environment = EnvironmentService.getEnvironment();
  if (isLoggedIn && environment.privateShop) {
    return true;
  } else if(environment.privateShop === false) {
   return true
  }else{
    return {redirect: '/not-logged-in'};
  }
}

export const HasShoppingCart = () => {
  let hasShoppingCart:number
  ShoppingCartService.shoppingCartCount.subscribe(count => hasShoppingCart = count);
  if (hasShoppingCart) {
    return true;
  } else {
    window.history.back()
  }
}

export const  OpenUrl = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const href = event.currentTarget.querySelector('a').getAttribute("href");
  RouterNavigate(href,'forward').then();
}

export const RouterBack = () => {
  const ionRouterElement: HTMLIonRouterElement = document.querySelector('ion-router');
  if(ionRouterElement) {
    return ionRouterElement.back();
  }else{
    history.back();
  }
};

export const RouterCanNavGoBackThenGo = async () => {
  const nav: HTMLIonNavElement = document.querySelector('ion-nav');
  if (nav && await nav.canGoBack()) {
    return nav.pop({skipIfBusy: true});
  }
  return RouterNavigate("/", 'back').then();
};

export const RouterOpenPayment = (event) => {
  event.preventDefault();
  const successUrl = location.href.split('?')[0];
  RouterNavigate('/payment/'+btoa(FixedEncodeURIComponent(successUrl))).then();
};

export const RouterGetUriParam = (name) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has(name)) {
    return urlParams.get(name);
  }else{
    return null;
  }
};

export const GetCategoryId = (path:string) => {
  if (path.includes('article-list')){
    if (isNaN(parseInt(path.split('/article-list/')[1]))){
      return  0
    }
    return parseInt(path.split('/article-list/')[1])

  }
};



export const RouterErrorHandling = async (error: IError, func?: () => Promise<void>) => {
  switch (error?.systemCode) {
    case "couponexpired":
      await ErrorToast(i18n('Unfortunately your coupon has expired.').t('de', 'Leider ist ihr Coupon abgelaufen.').get(),5000)
      break;
    case "addressnotfound":
      await ErrorToast(i18n('No corresponding address could be found.').t('de', 'Es konnte keine zugehörige Adresse gefunden werden.').get(),5000)
      break;
    case "addressnotcreated":
      await ErrorToast(i18n('Address could not be created.').t('de', 'Die Adresse konnte nicht erstellt werden.').get(),5000)
      break;
    case 'couponnotfound':
      await ErrorToast(i18n('Coupon not found. Please try another coupon.').t('de', 'Der Coupon konnte nicht gefunden werden. Bitte versuchen Sie es mit einem anderen Coupon.').get(),5000)
          break;
    case 'couponAlreadyUsed':
      await ErrorToast(i18n('Coupon already used. Please try another coupon.').t('de', 'Der Coupon wurde bereits verwendet. Bitte versuchen Sie es mit einem anderen Coupon.').get(),5000)
      AuthService.logout();
      if (!location.pathname.includes("coupon")){
        location.href = '/';
      }
      break;
    case "nousergroupsfound":
      await ErrorToast(i18n('No user groups available.').t('de', 'Keine Benutzergruppen vorhanden.').get(),5000)

    case 'validationerror':
      if(error.details.includes('mail')){
        await ErrorToast(i18n('Email is required.').t('de', 'E-Mail ist erforderlich.').get(),5000)

      }
      break;
    case "inputdatanotvalid":
    case "useralreadyexists":
      break
    case "wrongpassword":
      await ErrorToast(i18n('The password you entered is not correct.').t('de', 'Das eingegebene Passwort ist nicht korrekt.').get(),5000)
      break
    case "shopnotfound":
      await ErrorToast(i18n('No shop found with this ID.').t('de', 'Kein Shop mit dieser ID gefunden.').get(),5000)
      break

    case "nocontingentsfound":
      await ErrorToast(i18n('No contingents were found.').t('de', 'Es wurden keine Kontingente gefunden.').get(),5000)
      break
    case "usernotinshop":
    case "shopidmismatch":
      AuthService.logout();
      location.href = '/';
      await ErrorToast(i18n('No user found with this e-mail address.').t('de', 'Kein Benutzer mit dieser E-Mail-Adresse gefunden. ').get(),5000)
      break;
    case "refreshtokenhasexpired":
      await ErrorToast(i18n("You have been logged out. Please try to log in again.").t("de", "Sie wurden abgemeldet. Bitte versuchen Sie sich erneut anzumelden.").get(),5000);
      AuthService.logout()
      location.href = '/';
      break;
    case "invalidsignature":
    case "tokenhasexpired":
      let refreshToken = StorageService.get("credentials").refreshToken
        if (refreshToken){
          try {
            const refreshTokenFunc =  await AuthService.refreshToken(  EnvironmentService.getEnvironment().couponShop ? "coupon":"login", refreshToken)
            if (func && refreshTokenFunc !== undefined) {
              await func();
            }
          }catch (e){
            await RouterErrorHandling(e)
        }
    }else{
          await ErrorToast(i18n("You have been logged out. Please try to log in again.").t("de", "Sie wurden abgemeldet. Bitte versuchen Sie sich erneut anzumelden.").get(),5000);
          AuthService.logout();
          location.href = '/';
        }
      break;
    case "authenticationheaderisinvalid":
    case "authenticationhasfailed":
    case "authenticationrequired":

      await ErrorToast(i18n("You have been logged out. Please try to log in again.").t("de", "Sie wurden abgemeldet. Bitte versuchen Sie sich erneut anzumelden.").get(),5000);
      AuthService.logout();
      location.href = '/';
      break;
    default:
      await ErrorToast(error.message,5000)
  }


};
