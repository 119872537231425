
import {Deserializable} from './deserialize';
import {IContingent} from "./contingent";

export type Role = 'user' | 'admin' ;

export interface IRegister {
  name: string,
  identifier: string,
  password: string
}

export interface IUser {
  _id?: string;
  id?: string;
  email?: string;
  name?: string;
  updatedAt?: string;
  selected?: boolean;
    createdAt?: string;
  isDeleted?: boolean;
    created_at?: string;
  contingents?:IContingent[];
  coupon?: {
    coupon?: string;
    token?: string;
  };
  shortcode?:string;
  vacosId?:string;
  addresses?:string[];
  roles?: Role[];
  budgets?:string[];
  budgetIds?: string[];
}

export class User implements IUser, Deserializable {
  _id?: string;
  id?: string;
  email?: string;
  name?: string;
  updatedAt?: string;
  updated_at?: string;
  contingents?:IContingent[];
  selected?: boolean;
  createdAt?: string;
  isDeleted?: boolean;
  addresses?:string[];
  coupon?: {
    coupon?: string;
    token?: string;
  };
  shortcode?:string;
  vacosId?:string;
  roles?: Role[];
  budgets?:string[];
  budgetIds?: string[];

  deserializeInput(input: any) {
    this.name = String(input.name);
    this.email = String(input.email);
    this.vacosId = String(input.vacosId);
    this.shortcode = String(input.shortcode);

    this.roles = input.roles.map(String);  // Assuming all categories are string IDs

    return this;
  }

  deserialize(input: IUser) {
    if (input) {
      Object.assign(this, input);
    }
    return this;
  }
}
